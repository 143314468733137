<template>
    <div id="AdminSystemLogin" class="login-form-style">
        <ElForm
            :label-position="store.getters.labelPosition"
            label-width="180px"
            ref="loginFormRef"
            :model="loginFormData"
            :rules="loginFormRules"
        >
            <ElFormItem class="f14 f14-mb" label="メールアドレス" prop="email">
                <ElInput v-model="loginFormData.email" type="email"></ElInput>
            </ElFormItem>
            <ElFormItem class="f14 f14-mb" label="パスワード" prop="password">
                <ElInput
                    v-model="loginFormData.password"
                    type="password"
                ></ElInput>
            </ElFormItem>
            <div class="button-wrap">
                <button class="f18 f16-mb" @click.prevent="submitLoginForm">
                    ログイン
                </button>
            </div>
        </ElForm>
    </div>
</template>
<script>
export default {
    name: 'AdminSystemLogin',
}
</script>
<script setup>
import { reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { getLoginFormRules } from '@/utils/formRules'
import { userLogin } from '@/service/api.js'
const store = useStore()
const loginFormRef = ref(null)
const loginFormData = reactive({
    email: '',
    password: '',
})
const loginFormRules = getLoginFormRules()
function submitLoginForm() {
    loginFormRef.value.validate(async (valid) => {
        if (valid) {
            await userLogin(loginFormData.email, loginFormData.password)
        }
    })
}
</script>
<style lang="scss" scoped>
@import '@/style/element.scss';
</style>
